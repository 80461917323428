.ui-widget-header {
    background: #cca055;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
    background-image: url('/img/botonslider.png');
    background-repeat: no-repeat;
    border:none;
}

.ui-widget-content.plupload_container.ui-resizable.plupload_view_list {
    border: 1px solid #000000;
}

.ui-slider.ui-slider-horizontal.ui-widget.ui-widget-content.ui-corner-all {
    border: 1px solid #ddd;
}




body .ui-widget-header {
    background: #cca055;
}

body .ui-state-default,
body .ui-widget-content .ui-state-default,
body .ui-widget-header .ui-state-default {
    background-image: url('/img/botonslider.png');
    background-repeat: no-repeat;
    border:none;
}

body .ui-widget-content.plupload_container.ui-resizable.plupload_view_list {
    border: 1px solid #000000;
}

body .ui-slider.ui-slider-horizontal.ui-widget.ui-widget-content.ui-corner-all {
    border: 1px solid #ddd;
}